import React from "react";
import {HeadFC, Link, navigate} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";
import {GatsbyImage} from "gatsby-plugin-image";
import {FaBluetooth} from "@react-icons/all-files/fa/FaBluetooth";
import {FaSnowflake} from "@react-icons/all-files/fa/FaSnowflake";
import {GiCarDoor} from "@react-icons/all-files/gi/GiCarDoor";
import {BsPeopleFill} from "@react-icons/all-files/bs/BsPeopleFill";
import {FaCar} from "@react-icons/all-files/fa/FaCar";
import {vehicleCategories, vehiclesOfCategory, VehicleType} from "../data/data";
import {useImage} from "../hooks/use_image";
import {formatVehicleName} from "../helpers";


const VehiclesPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight text-gray-900 font-poppins">
              Cars for rental
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8 mb-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl mb-2"><b>Vehicle models</b></h2>
            <p>At our car rental company, we take pride in offering our customers the best quality vehicles for
              their travels. We have a wide selection of new and well-maintained cars to choose from, ensuring that
              you
              have a safe and reliable driving experience during your stay. We understand the importance of having a
              vehicle that meets your needs and preferences, which is why we make it a priority to provide our
              customers
              with top-notch service and the highest quality vehicles available. Whether you're traveling for business
              or pleasure, you can trust our rental cars to get you where you need to go.</p>
          </div>
        </section>
        {vehicleCategories.map(category => (
          <section className="bg-[#F8F8F8] pb-10">
            <CategoryHeader title={`Category ${category}`}/>
            <div
              className="mx-auto grid max-w-6xl grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
              {vehiclesOfCategory(category).map(vehicle =>
                vehicle.type == VehicleType.CAR ?
                  <CarCard
                    name={vehicle.name}
                    transmission={vehicle.transmission}
                    seats={vehicle.seats}
                    doors={vehicle.doors}
                    hasBluetooth={true}
                    image={vehicle.image}
                    imageAlt={"A Nissan Qashqai white car"}
                    onGetQuoteTap={async () => {
                      await navigate(`/?selectedCar=${encodeURI(vehicle.name)}`);
                    }}
                  />
                  : <BikeCard
                    name={vehicle.name}
                    transmission={vehicle.transmission}
                    image={vehicle.image}
                    imageAlt={"A Honda CB500F white motorcycle"}
                    onGetQuoteTap={async () => {
                      await navigate(`/?selectedCar=${encodeURI(vehicle.name)}`);
                    }}
                  />
              )}
            </div>
          </section>
        ))}
      </main>
      <Footer/>
    </>
  )
}

const CategoryHeader = (props: { title: string, id?: string }) => (
  <div className="orange-gradient-fixed mb-10 max-w-6xl mx-auto lg:rounded-md" id={props.id}>
    <h2 className="mx-auto grid pl-6 max-w-6xl text-2xl text-white font-rubik font-semibold py-4">{props.title}</h2>
  </div>
)

const BikeCard = (props: { name: string, transmission: string, image: string, imageAlt: string, onGetQuoteTap: () => void }) => (
  <article
    className="rounded-xl bg-white p-4 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300">
    <div className="relative flex items-end overflow-hidden rounded-xl p-8">
      <GatsbyImage image={useImage(props.image)} alt={props.imageAlt}/>
    </div>

    <div className="mt-1 p-2">
      <h2 className="text-gray-800 font-rubik font-bold text-2xl mb-2">{props.name}</h2>
      <p className="mt-1 text-sm text-gray-500">
        <FaCar className="text-gray-800 mr-0.5"/> Transmission: <b>{props.transmission}</b>
      </p>
      <div className="mt-10 flex items-end justify-end">
        <div
          className="flex items-center space-x-3 rounded-lg px-8 py-2 text-white duration-100 orange-gradient">
          <button className="text-sm font-semibold" onClick={props.onGetQuoteTap}>Get a Quote</button>
        </div>
      </div>
    </div>
  </article>
);

const CarCard = (props: { name: string, transmission: string, seats: number, doors: number, hasBluetooth: boolean, image: string, imageAlt: string, onGetQuoteTap: () => void }) => (
  <article
    className="rounded-xl bg-white p-4 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300">
    <div className="relative flex items-end overflow-hidden rounded-xl p-8">
      <GatsbyImage image={useImage(props.image)} alt={props.imageAlt}/>
    </div>

    <div className="mt-1 p-2">
      <h2 className="text-gray-800 font-rubik font-bold text-2xl mb-2">{props.name}</h2>
      <p className="mt-1 text-sm text-gray-500">
        <FaCar className="text-gray-800 mr-0.5"/> Transmission: <b>{props.transmission}</b>
      </p>
      <p className="mt-1 text-sm text-gray-500">
        <BsPeopleFill className="text-gray-800 mr-0.5"/> Seats: {props.seats}
      </p>
      <p className="mt-1 text-sm text-gray-500">
        <GiCarDoor className="text-gray-800 mr-0.5"/> Doors: {props.doors}
      </p>
      <p className="mt-1 text-sm text-gray-500">
        <FaSnowflake className="text-gray-800 mr-0.5"/> AC
      </p>
      {props.hasBluetooth &&
        <p className="mt-1 text-sm text-gray-500">
          <FaBluetooth className="text-gray-800 mr-0.5"/> Bluetooth
        </p>
      }
      <div className="mt-3 flex items-end justify-end">
        <div
          className="flex items-center space-x-3 rounded-lg px-8 py-2 text-white duration-100 orange-gradient">
          <button className="text-sm font-semibold" onClick={props.onGetQuoteTap}>Get a Quote</button>
        </div>
      </div>
    </div>
  </article>
);

//TODO: Change the SEO TAGS
export const Head: HeadFC = () => (
  <SEO
    title="New and Reliable Vehicles | Cool Car Rentals"
    description="Discover a wide selection of new and well-maintained rental cars at Coolcar in Paros. Enjoy a safe and reliable driving experience for business or pleasure."
  />
);

export default VehiclesPage;